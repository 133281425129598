// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-theme-grape-blog-templates-post-js": () => import("./../../../src/gatsby-theme-grape-blog/templates/post.js" /* webpackChunkName: "component---src-gatsby-theme-grape-blog-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clickme-js": () => import("./../../../src/pages/clickme.js" /* webpackChunkName: "component---src-pages-clickme-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-does-music-influence-sports-performance-js": () => import("./../../../src/pages/does-music-influence-sports-performance.js" /* webpackChunkName: "component---src-pages-does-music-influence-sports-performance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-recruitment-js": () => import("./../../../src/pages/research-recruitment.js" /* webpackChunkName: "component---src-pages-research-recruitment-js" */),
  "component---src-pages-sinclair-calculator-js": () => import("./../../../src/pages/sinclair-calculator.js" /* webpackChunkName: "component---src-pages-sinclair-calculator-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

