import styled from "@emotion/styled"
import { Form, Formik } from "formik"
import React from "react"
import { MdCalculate } from "react-icons/md"
import * as yup from "yup"
import { Button } from "../basic"
import { Field, FieldError, RadioField } from "../form/field"

const validationSchema = yup.object({
  gender: yup.string().required("select male or female"),
  weight: yup
    .number()
    .typeError("weight must be a number")
    .required("weight is required")
    .positive("weight must be greater than zero"),
  total: yup
    .number()
    .typeError("total must be a number")
    .required("total is required")
    .positive("total must be greater than zero"),
})

const StyledForm = styled(Form)`
  fieldset {
    margin-bottom: 1.45rem;
  }
`

const StyledField = styled(Field)`
  margin-bottom: 1.45rem;
`

const RadioGroup = styled.fieldset`
  display: flex;
  align-items: center;

  label:first-of-type input[type="radio"] {
    margin-left: 0;
  }
`

const RadioError = styled(FieldError)`
  flex: 1;
  text-align: right;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  [type="submit"] {
    margin-left: auto;
  }
`

export const SinclairForm = ({ children, onSubmit = () => {} }) => {
  return (
    <Formik
      initialValues={{
        gender: "",
        weight: "",
        total: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <StyledForm>
        <RadioGroup>
          <RadioField name="gender" value="male" label="Male" />
          <RadioField name="gender" value="female" label="Female" />
          <RadioError name="gender" />
        </RadioGroup>

        <StyledField
          inputMode="decimal"
          name="weight"
          label="Bodyweight (kg)"
          placeholder="athlete weight in kg"
        />
        <StyledField
          inputMode="decimal"
          name="total"
          label="Total (kg)"
          placeholder="total lifted in kg"
        />

        <Footer>
          {children}
          <Button type="submit" icon={MdCalculate}>
            Calculate
          </Button>
        </Footer>
      </StyledForm>
    </Formik>
  )
}
