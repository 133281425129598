// https://iwf.sport/wp-content/uploads/downloads/2017/01/Sinclair_Coefficients_2017.pdf
const SINCLAIR = {
  male: {
    A: 0.75194503,
    b: 175.508,
  },
  female: {
    A: 0.783497476,
    b: 153.655,
  },
}

const getSinclairComponents = (gender) => {
  const components = SINCLAIR[gender]
  if (!components) {
    throw new Error(`${gender} should equal either 'male' or 'female'`)
  }
  return components
}

const calculateSinclairCoefficient = (weight, A, b) => {
  const X = Math.log(weight / b) / Math.log(10)

  if (weight > b) {
    return 1
  }

  return Math.pow(10, A * X ** 2)
}

export const calculateScore = ({ gender, weight, total }) => {
  const { A, b } = getSinclairComponents(gender)
  const sinclair = calculateSinclairCoefficient(weight, A, b)
  return sinclair * total
}