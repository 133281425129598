import styled from "@emotion/styled"
import React, { useState } from "react"
import { SinclairForm } from "./form"
import { calculateScore } from "./sinclair"

const Score = styled.div`
  strong {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
`

export const SinclairCalculator = () => {
  const [sinclairScore, setSinclairScore] = useState()

  const onSubmit = ({ gender, weight, total }) => {
    const score = calculateScore({ gender, weight, total })
    setSinclairScore(score)
  }

  const formattedScore = sinclairScore?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    useGrouping: false,
  })

  return (
    <SinclairForm onSubmit={onSubmit}>
      {formattedScore && (
        <Score>
          <label>Sinclair total</label>
          <strong>{formattedScore}</strong>
        </Score>
      )}
    </SinclairForm>
  )
}
