import styled from "@emotion/styled"
import { ErrorMessage, Field as FormikField } from "formik"
import React, { memo } from "react"
import { theme } from "../../utils/theme"
import { scale } from "../../utils/typography"

const Container = styled.div`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  border: 0;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
`

const FieldDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledField = styled(FormikField)``

const ErrorBlock = styled.div`
  ${scale(-1 / 4)};
  color: ${theme.colors.error};

  span::before {
    content: "*";
    color: ${theme.colors.primary};
  }
`

export const FieldError = ({ className, name }) => (
  <ErrorBlock className={className}>
    <ErrorMessage name={name} component="span" />
  </ErrorBlock>
)

export const Field = memo((props) => {
  const { name, label, className } = props
  const formikProps = { ...props }
  delete formikProps.className
  return (
    <Container name={name} className={className}>
      <FieldDetails>
        <label htmlFor={name}>{label}</label>
        <FieldError name={name} />
      </FieldDetails>
      <StyledField {...formikProps} />
    </Container>
  )
})

export const RadioField = memo((props) => {
  const { label, className } = props
  const formikProps = { ...props }
  delete formikProps.className
  return (
    <RadioLabel className={className}>
      <StyledField type="radio" {...formikProps} />
      {label}
    </RadioLabel>
  )
})
